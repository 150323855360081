<template>
  <div class="">
    <h2>アンケート</h2>
    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body mt-1">
        <div class="d-flex">
          <div class="mr-1">
            <b-avatar
              size="35"
              src="https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/brand%2Fgirl-bule.png?alt=media&token=a579655b-d85a-437a-93ce-034100fcb34e"
              variant="light-primary"
            />
          </div>
          <div class="">
            事前にアンケートにご回答頂くと、より早くお仕事をご紹介できます。遠慮せず、ご希望をお聞かせください！
          </div>
        </div>
      </div>
    </b-alert>
    <b-card
      title=""
    >
      <b-form>
        <b-row
          v-for="(item, index) in menulist"
          :key="index"
        >
          <InputParts
            v-if="useValueDicList.includes(item.type)"
            :label="item.label"
            :type="item.type"
            :choice="typeof item.choice !== 'undefined'? item.choice: []"
            :value-dic="inputForm[item.key]"
            :disabled="item.disabled || false"
            :default-value="typeof item.defaultValue !== 'undefined'? item.defaultValue: []"
            :explain="typeof item.explain !== 'undefined'? item.explain: []"
            :select="item.type ==='checkbox' ? choice: []"
            :placeholder-text="item.placeholderText"
            @inputData="inputForm[item.key] = $event"
          />
          <InputParts
            v-else-if="useValueListList.includes(item.type)"
            :label="item.label"
            :type="item.type"
            :choice="typeof item.choice !== 'undefined'? item.choice: []"
            :value-list="typeof inputForm[item.key] !== 'undefined'? inputForm[item.key] : []"
            :default-value="typeof item.defaultValue !== 'undefined'? item.defaultValue: []"
            :disabled="item.disabled || false"
            :explain="typeof item.explain !== 'undefined'? item.explain: []"
            :select="item.type === 'checkboxplain' ? item.select: []"
            :placeholder-text="item.placeholderText"
            @inputData="inputForm[item.key] = $event"
          />
          <InputParts
            v-else
            :label="item.label"
            :type="item.type"
            :choice="typeof item.choice !== 'undefined'? item.choice: []"
            :value="item.type !=='checkbox' ?inputForm[item.key]: ''"
            :disabled="item.disabled || false"
            :explain="typeof item.explain !== 'undefined'? item.explain: []"
            :select="item.type ==='checkbox' ? choice: []"
            :placeholder-text="item.placeholderText"
            @inputData="inputForm[item.key] = $event"
          />
        </b-row>
        <div v-if="errorList.length > 0">
          <b-alert
            variant="danger"
            show
            class="alertSpace"
          >
            <div class="alert-body">
              <div class="d-flex mb-2">
                <div class="mr-1">
                  <b-avatar
                    size="30"
                    src="https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/brand%2Fgirl-green.png?alt=media&token=decd9d77-1d03-4797-b794-a03bf691d4e5"
                    variant="light-primary"
                  />
                </div>
                <div class="">
                  {{ errorMSG }}
                </div>
              </div>
              <div
                v-for="(item, index) in errorList"
                :key="index"
                class=""
              >
                ・<b>{{ item }}</b>
              </div>
            </div>
          </b-alert>
        </div>
        <b-row class="mt-2">
          <!-- submit and reset -->
          <b-col class="text-center">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="confirm"
            >
              登録する
              <b-spinner
                v-if="status === 2"
                small
              />
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <b-alert
      variant="secondary"
      show
    >
      <div class="alert-body mt-1">
        <div class="d-flex">
          <div class="mr-1">
            <b-avatar
              size="35"
              src="https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/brand%2Fgril-red.png?alt=media&token=4033ef9a-cd19-48e6-baf6-85065c0a3c96"
              variant="light-primary"
            />
          </div>
          <div class="">
            転職前は不安がいっぱいですが、転コレエージェントはあなたの味方です！どんなお仕事が良いのか、ご希望をたくさん聞かせて下さいね！
          </div>
        </div>
      </div>
    </b-alert>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BForm, BButton, BCard, BSpinner, BAlert, BAvatar,
} from 'bootstrap-vue'
import InputParts from '@/components/molecules/input/InputParts.vue'
/* eslint-disable */
import menulist from '@/components/conf/アンケート.json'
/* eslint-enable */
import getSingleData from '@/firestore/data/get'
import UpdateByUserID from '@/firestore/data/UpdateByUserID'
import CreateOrUpdateData from '@/firestore/user/CreateOrUpdateData'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    BCard,
    InputParts,
    BSpinner,
    BAlert,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      inputForm: {},
      status: 1,
      isShow: false,
      menulist,
      selected: [],
      errorList: [],
      useValueDicList: ['checkbox', 'selectSingle', 'languageLevelSelect', 'addressInput', 'radio'],
      useValueListList: ['checkboxplain', 'qualificationInput', 'serviceCategory', 'languageLevelSelect'],
    }
  },
  computed: {
  },
  async mounted() {
    const data = {
      collection: 'hearing',
    }
    const response = await getSingleData(data)
    if (response.status === 'success') {
      this.inputForm = response.data
    } else {
      window.console.log('error', response.msg)
    }
  },
  methods: {
    isInvalid() {
      this.errorList = []
      const datakeys = Object.keys(this.inputForm)
      // window.console.log('memo', datakeys)
      // window.console.log('memo', this.inputForm)
      this.menulist.forEach(item => {
        // 全く操作していない場合：undefinedになる
        if (item.required && !datakeys.includes(item.key)) {
          // jobtypeがundefined(空白)のとき
          if (item.key === 'jobtype') {
            // window.console.log(item.key, '💀', datakeys.includes('jobtype_other'))
            // jobtype_otherも空白のとき
            if (!datakeys.includes('jobtype_other') || this.inputForm.jobtype_other === '') {
              if (!this.errorList.includes('希望職種')) this.errorList.push('希望職種')
              // window.console.log('🏎', item.key)
              return
            }
            return
          }
          if (item.key === 'jobtype_other') {
            // window.console.log('🔑', item.key)
            if (!datakeys.includes('jobtype')) {
              if (!this.errorList.includes('希望職種')) this.errorList.push('希望職種')
              // window.console.log('🤖', item.key)
              return
            }
            return
          }
          if (!this.errorList.includes(item.label)) {
            this.errorList.push(item.label)
            // window.console.log('🚀', item.key)
          }
        }
        // フォームを選択したが空白の場合
        if (item.required && datakeys.includes(item.key) && this.inputForm[item.key] === '') {
          const offlist = ['jobtype', 'jobtype_other']
          if (!offlist.includes(item.key)) {
            if (!this.errorList.includes(item.label)) {
              this.errorList.push(item.label)
              // window.console.log('🍌', item.key)
            }
          }
        }
      })
      // window.console.log('memo', datakeys)
      // window.console.log('memo', this.inputForm)
      if (
        datakeys.includes('jobtype')
        && datakeys.includes('jobtype_other')
        && this.inputForm.jobtype.length === 0
        && this.inputForm.jobtype_other === ''
      ) {
        if (!this.errorList.includes('希望職種')) this.errorList.push('希望職種')
      }
      if (this.errorList.length > 0) {
        this.errorMSG = '下記の項目を入力してください。'
        return true
      }
      return false
    },
    async confirm() {
      this.status = 2
      const isInvalid = this.isInvalid()
      if (isInvalid) {
        this.status = 1
        return
      }
      this.inputForm.displayStatus = 'active'
      const d = {
        collection: 'hearing',
        data: this.inputForm,
      }
      const updateData = new CreateOrUpdateData()
      const response = await updateData.play(d)
      if (response.status === 'success') {
        response.type === 'create' ? this.updateFlag() : this.success()
      } else {
        window.console.log('error', response.msg)
      }
    },
    async updateFlag() {
      const d = {
        collection: 'user',
        data: {
          isUserHearing: true,
        },
      }
      const updateByUserID = new UpdateByUserID()
      const response = await updateByUserID.update(d)
      if (response.status === 'success') {
        this.success()
      } else {
        window.console.log('error', response.msg)
      }
    },
    showBtn() {
      this.$ref.sbchild.$emit('success')
    },
    success() {
      this.$swal({
        title: '登録完了！',
        text: 'データが正常に登録されました。',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(() => {
        this.status = 1
        if (this.$router.currentRoute.name === 'dashboad') {
          this.$emit('childevent')
        } else {
          this.$router.push({ name: 'hearing' })
        }
      })
    },
  },
}
</script>
