<template>
  <div class="space_">
    <b-row class="match-height">
      <b-col lg="12">
        <EditHearing />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import EditHearing from '@/components/jobSeeker/hearing/EditHearing.vue'

export default {
  components: {
    BRow,
    BCol,
    EditHearing,
  },
}
</script>

<style scoped>
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .space_ {
    width: 50%;
    margin: 0 auto;
  }
}
</style>
